import React from 'react';
import {
  ArrayField,
  BooleanField,
  Datagrid,
  DateField,
  FunctionField,
  Link,
  NumberField,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  UrlField,
  useShowController,
} from 'react-admin';
import * as dateFns from 'date-fns';
import SubscriptionStatusField from '../../components/SubscriptionStatusField';
import SubscriptionOperations from './elements/SubscriptionOperations';
import SubscriptionRenewalRequests from './elements/SubscriptionRenewalRequests';

const SubscriptionShow = (props: ShowProps) => {
  const { record } = useShowController(props);

  if (!record) return null;

  return (
    <Show {...props} title="Subscriptions">
      <TabbedShowLayout>
        <Tab label="summary" contentClassName="two-columns-layout">
          <h5>Subscription</h5>
          <SubscriptionStatusField />
          <DateField source="createdAt" label="Purchase date" />
          <DateField source="currentPeriod.timeStart" label="Start Date" />
          <DateField source="currentPeriod.timeEnd" label="End Date" />
          <TextField source="yearOfSubscription" label="Year of Subscription" />
          <TextField source="code" />
          <TextField source="bpPulseCode" label="BP Pulse Code" />
          <TextField source="bundleType.name" label="Bundle" />
          <NumberField
            source="currentPeriodPrice"
            label="Price"
            locales="en-GB"
            options={{ style: 'currency', currency: 'GBP' }}
          />
          <TextField source="usedPromoCode" />
          <FunctionField
            source="reseller"
            label="Reseller"
            render={(sub: any) => {
              return sub.resellerBusiness ? record.resellerBusiness.displayName : 'Zoom';
            }}
          />
          <FunctionField
            source="user"
            label="Subscriber"
            render={(sub: any) => {
              if (sub.user) {
                return (
                  <Link to={`/Users/${sub.user.id}/show`}>
                    {sub.user.details.firstName} {sub.user.details.lastName}
                  </Link>
                );
              }
              return '';
            }}
          />
          <FunctionField
            label="Requested Call"
            sortable={false}
            render={(sub: any) => {
              return sub.user.callRequest ? (
                <Link to={`/CallRoster/${sub.user.callRequest.id}/show`}>Yes</Link>
              ) : (
                'No'
              );
            }}
          />
          <h5>Address/Contact</h5>
          <TextField source="subscriberDetails.phoneNumber" label="Phone Number" />
          <TextField source="subscriberDetails.addressLine1" label="Address Line 1" />
          <TextField source="subscriberDetails.addressLine2" label="Address Line 2" />
          <TextField source="subscriberDetails.town" label="Town" />
          <TextField source="subscriberDetails.county" label="County" />
          <TextField source="subscriberDetails.postCode" label="Post Code" />
          <h5>Car</h5>
          <TextField source="additionalData.carMakerName" label="Car Maker" />
          <TextField source="additionalData.carModelName" label="Car Model" />
          <FunctionField
            source="additionalData.estimatedCarDeliveryDate"
            label="Estimated Car Delivery Date"
            render={(row: any) => {
              if (!row.additionalData.estimatedCarDeliveryDate) {
                return '';
              }

              return dateFns.format(
                Number(row.additionalData.estimatedCarDeliveryDate),
                'yyyy-MM-dd',
              );
            }}
          />
          <BooleanField source="additionalData.isCarPartOfFleet" label="is Car Part Of Fleet" />
          <h5>Payment</h5>
          <TextField source="stripeSubscriptionId" />
          <UrlField
            target="_blank"
            source="stripeSubscriptionUrl"
            label="Stripe subscription URL"
          />
          <h5>Invoices</h5>
          <ArrayField source="stripeInvoices">
            <Datagrid>
              <TextField source="id" />
              <UrlField source="stripeInvoiceUrl" />
              <TextField source="status" />
            </Datagrid>
          </ArrayField>
          <h5>Subscription Operations</h5>
          <SubscriptionOperations />
        </Tab>
        <Tab label="Renewal Requests" path="renewalRequests">
          <SubscriptionRenewalRequests user={record} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
export default SubscriptionShow;
