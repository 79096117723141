import { gql } from '@apollo/client';
import _set from 'lodash.set';
import _clonedeep from 'lodash.clonedeep';
import {
  RaFetchType,
  RaListParams,
  RaListResponse,
  RaParams,
  RaQuery,
} from '../../interfaces/RaQueries';
import { BundleCodeType } from '../../interfaces/types.generated';
import { ApiResponse } from '../../interfaces/ApiQueries';
import getCodesListParams from '../../utils/getCodesListParams';

interface ListResult {
  bundleCodesV2: {
    codes: Array<BundleCodeType>;
    overallCount: number;
  };
}

const Codes = (raFetchType: RaFetchType, params: RaParams<BundleCodeType>): RaQuery => {
  switch (raFetchType) {
    case 'GET_LIST':
      return {
        query: gql`
          query ($limit: Int, $offset: Int, $searchPhrase: String) {
            bundleCodesV2(params: { limit: $limit, offset: $offset, searchPhrase: $searchPhrase }) {
              codes {
                id
                code
                codeType
                available
                subscription {
                  status
                  currentPeriod {
                    timeEnd
                  }
                }
              }
              overallCount
            }
          }
        `,
        variables: getCodesListParams(params as RaListParams),
        parseResponse: ({ data }: ApiResponse<ListResult>): RaListResponse<BundleCodeType> => ({
          data: data.bundleCodesV2.codes.map((code) => {
            const newCode = _clonedeep(code);
            if (newCode.subscription?.currentPeriod?.timeEnd) {
              _set(
                newCode,
                'subscription.currentPeriod.timeEnd',
                newCode.subscription.currentPeriod.timeEnd * 1000,
              );
            }
            return newCode;
          }),
          total: data.bundleCodesV2.overallCount,
        }),
      };
    case 'CREATE':
      return {
        query: gql`
          mutation ($quantity: Int!) {
            generateBundleCodes(quantity: $quantity)
          }
        `,
        variables: { quantity: params.data.quantity },
        parseResponse: (): any => ({
          data: { id: '', data: {} },
        }),
      };
    default:
      throw new Error(`Unknown fetch type: ${raFetchType}`);
  }
};

export default Codes;
