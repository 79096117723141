/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Datagrid,
  DateField,
  downloadCSV,
  Filter,
  FunctionField,
  List,
  ListProps,
  TextField,
  TextInput,
} from 'react-admin';
import format from 'date-fns/format';
import jsonExport from 'jsonexport/dist';

const exporter = (codes: any) => {
  const codesForExport = codes.map((code: any) => {
    const { id, subscription, ...codeForExport } = code;
    codeForExport.status = code.subscription?.status ? code.subscription.status : 'Not assigned';
    codeForExport.expiration = code.subscription?.currentPeriod?.timeEnd
      ? format(new Date(code.subscription.currentPeriod.timeEnd), 'yyyy-MM-dd')
      : '';
    return codeForExport;
  });
  jsonExport(
    codesForExport,
    {
      headers: ['code', 'status', 'expiration'],
    },
    (err: any, csv: any) => {
      downloadCSV(csv, 'codes');
    },
  );
};

const CodesFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput label="Code" source="q" alwaysOn variant="outlined" />
    </Filter>
  );
};

const CodesList = (props: ListProps) => (
  <List {...props} bulkActionButtons={false} exporter={exporter as any} filters={<CodesFilter />}>
    <Datagrid rowClick="show">
      <TextField source="code" sortable={false} />
      <FunctionField
        label="Code Status"
        render={(record: any) => {
          if (record.available === false) return 'Expired';
          return record.subscription ? 'Assigned' : 'Not assigned';
        }}
      />
      <FunctionField
        label="Subscription Status"
        render={(record: any) => {
          return record.subscription ? record.subscription.status : '';
        }}
      />
      <TextField source="codeType" label="Code Type" />
      <DateField source="subscription.currentPeriod.timeEnd" label="Expiration date" />
    </Datagrid>
  </List>
);

export default CodesList;
